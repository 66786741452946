import {
  TaskActionProps,
  CreateRecordTaskProps,
  UpdateRecordTaskProps,
  FETCH_SIMPLE_PROJECT_TASKS,
  FETCH_SIMPLE_PROJECT_TASK_COUNT,
  CREATE_SIMPLE_RECORD_TASK,
  CLOSE_SIMPLE_TASK,
  REJECT_SIMPLE_TASK,
  CREATE_SIMPLE_SIGNED_URL_TASK,
  GET_SIMPLE_TASK_CODES,
  UPDATE_SIMPLE_RECORD_TASK,
  DeleteFieldRecordProps,
  DELETE_SIMPLE_FIELD_RECORD,
  UPLOAD_FILES,
  FETCH_SIMPLE_TASK,
} from 'global'
import { gqlClient, OrgGqlClient } from 'lib/graphql'
import { logError, raiseSentryError } from 'utils'

const getProjectId = () => {
  const currentUrl = window.location.href
  const regex = /\/simple-project\/(\d+)/
  const match = currentUrl.match(regex)
  if (match) {
    const projectId = match[1]
    return projectId
  }
}

export async function uploadToSignedURL(signedUrl: string, file: File) {
  try {
    const _headers = new Headers()
    _headers.append('Content-Type', 'application/octet-stream')
    const requestOptions = {
      method: 'PUT',
      headers: _headers,
      body: file,
    }

    const response = await fetch(signedUrl, requestOptions as any)
    if (!response.ok) {
      return {
        error: `Failed to upload: ${response.status} ${response.statusText}`,
      }
    }
    return { error: false }
  } catch (error) {
    logError('UnknownError', error)
    return { error }
  }
}

export class SimpleProjectService {
  async getTaskList(offset, filters = {}) {
    try {
      const { data, loading } = await gqlClient.query({
        query: FETCH_SIMPLE_PROJECT_TASKS,
        variables: { offset, filters, projectId: getProjectId() },
      })
      return { data, loading }
    } catch (error) {
      logError('NetworkError', error, ['simple project', 'getting task list'])
      return { data: null, loading: false }
    }
  }

  async getTaskDetails(pk) {
    try {
      const { data, loading } = await gqlClient.query({
        query: FETCH_SIMPLE_TASK,
        variables: { pk, projectId: getProjectId() },
      })
      return { data, loading }
    } catch (error) {
      logError('NetworkError', error, [
        'simple project',
        'getting task details',
      ])
      return { data: null, loading: false }
    }
  }

  async getTaskCount(offset, filters = {}) {
    try {
      const { data, loading } = await gqlClient.query({
        query: FETCH_SIMPLE_PROJECT_TASK_COUNT,
        variables: { offset, filters, projectId: getProjectId() },
      })
      return { data, loading }
    } catch (error) {
      logError('NetworkError', error, ['simple project', 'getting task'])
      return { data: null, loading: false }
    }
  }

  async getTaskCodeList() {
    const { data } = await gqlClient.query({
      query: GET_SIMPLE_TASK_CODES,
    })
    return data
  }

  async uploadFile(file: File | Blob, orgId: number, layerId: number) {
    try {
      const { data } = await OrgGqlClient.mutate({
        mutation: UPLOAD_FILES as any,
        variables: {
          context: 'record_field_image',
          extraArgs: JSON.stringify({ layerId, organizationId: orgId }),
          imageFile: file,
        },
      })
      return data.uploadImage.url
    } catch (err) {
      raiseSentryError(err, {
        uploading_file_via_gql: {
          scope: 'Simple Project Service',
          action: 'uploading file through graphql mutation',
          file,
          layerId,
        },
      })
    }
  }

  async createSignedURL(fileExtension: string, orderId: number | string) {
    try {
      const { data, errors } = await gqlClient.mutate({
        mutation: CREATE_SIMPLE_SIGNED_URL_TASK as any,
        variables: { orderId, fileExtension },
      })
      const { signedUrl: recordSignedUrl } = data
      const { blobUrl, signedUrl } = recordSignedUrl ?? {}
      return { blobUrl, signedUrl, errors }
    } catch (error) {
      raiseSentryError(error, {
        creating_signed_url: {
          scope: 'Simple Project Service',
          action: 'creating new signed url',
          fileExtension,
        },
      })
      return { blobUrl: null, signedUrl: null, errors: error }
    }
  }

  async uploadViaSignedUrl(
    file: File,
    orderId: number | string,
    organizationId: number,
    fallBack?: (
      file: File,
      organizationId: number,
      layerId: number | string
    ) => Promise<any>
  ) {
    try {
      // request signed url from the backend
      const fileExtension = file.name.split('.').pop()
      const { blobUrl, signedUrl, errors } = await this.createSignedURL(
        fileExtension,
        orderId
      )
      if (errors) {
        logError('NetworkError', errors, [
          'simple project',
          'obtaining signed url',
        ])
        // try to upload via backend server graphql
        if (fallBack) return fallBack(file, organizationId, orderId)
        throw errors
      }
      // upload the actual file to the signed url
      const { error } = await uploadToSignedURL(signedUrl, file)
      if (error) {
        logError('NetworkError', error, [
          'simple project',
          'uploadign to signed url',
        ])
        // try to upload via backend server graphql
        if (fallBack) return fallBack(file, organizationId, orderId)
        throw error
      }
      return blobUrl
    } catch (err) {
      raiseSentryError(err, {
        uploading_via_signedurl: {
          scope: 'Simple Project Service',
          action: 'uploading via signed url',
          file,
        },
      })
    }
  }

  async uploadGpxFile(file: File, orderId: number | string) {
    try {
      // request signed url from the backend
      const fileExtension = file.name.split('.').pop()
      const { blobUrl, signedUrl, errors } = await this.createSignedURL(
        fileExtension,
        orderId
      )
      if (errors) {
        logError('NetworkError', errors, [
          'simple project',
          'creating signed url for a gpx file',
        ])
        throw errors
      }
      // upload the actual file to the signed url
      const { error } = await uploadToSignedURL(signedUrl, file)
      if (error) {
        logError('NetworkError', error, ['uploading gpx to signed url'])
        throw error
      }
      return { blobUrl, signedUrl }
    } catch (err) {
      raiseSentryError(err, {
        uploading_gpx_file: {
          scope: 'Simple Project Service',
          action: 'uploading gpx file',
          file,
        },
      })
    }
  }

  async createRecordTask({
    recordData,
    onSuccess,
    onFail,
  }: CreateRecordTaskProps) {
    try {
      const { data } = await gqlClient.mutate({
        mutation: CREATE_SIMPLE_RECORD_TASK,
        variables: { recordInput: recordData },
      })
      if (data?.rasedUpdateRecord && typeof onSuccess === 'function')
        onSuccess()
    } catch (error) {
      if (onFail) onFail()
      raiseSentryError(error, {
        creating_new_record: {
          scope: 'Simple Project Service',
          action: 'creating new record',
          data: recordData,
        },
      })
    }
  }

  async updateRecord({
    recordId,
    taskId,
    formData,
    onSuccess,
    onFail,
  }: UpdateRecordTaskProps) {
    try {
      const { data } = await gqlClient.mutate({
        mutation: UPDATE_SIMPLE_RECORD_TASK,
        variables: { recordInput: { recordId, taskId, formData } },
      })
      const { rasedUpdateRecord } = data
      if (data?.rasedUpdateRecord && typeof onSuccess === 'function')
        onSuccess()
      return rasedUpdateRecord
    } catch (error) {
      if (onFail) onFail()
      raiseSentryError(error, {
        updating_record: {
          scope: 'Simple Project Service',
          action: 'updating exist record',
          data: formData,
        },
      })
    }
  }

  async takeAction({ actionType, taskId, onSuccess, onFail }: TaskActionProps) {
    const actionMutations = {
      close: CLOSE_SIMPLE_TASK,
      reject: REJECT_SIMPLE_TASK,
    }
    try {
      await gqlClient.mutate({
        mutation: actionMutations[actionType],
        variables: { taskInput: { taskId } },
      })
      if (onSuccess) onSuccess()
    } catch (error) {
      if (onFail) onFail()
      throw error
    }
  }

  async deleteFieldRecord({
    recordInput,
    onSuccess,
    onFail,
  }: DeleteFieldRecordProps) {
    try {
      await gqlClient.mutate({
        mutation: DELETE_SIMPLE_FIELD_RECORD,
        variables: { recordInput },
      })
      if (onSuccess) onSuccess()
    } catch (error) {
      if (onFail) onFail()
      raiseSentryError(
        error,
        {},
        {
          scope: 'Simple Project Service',
          action: 'deleting a record',
        }
      )
    }
  }
}
