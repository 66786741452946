import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Layout } from 'shared'
import {
  Basemaps,
  HeaderSideList,
  SideListPagination,
  TaskList,
} from 'components'
import { SideBarDrawer } from 'components/AppDrawers'
import { SimpleProjectMap } from 'components/AppMaps/SimpleProjectMap'
import { RootState } from 'store'
import { buildFilters } from 'utils'
import {
  fetchSimpleProjectTasks,
  getSimpleProjectSchema,
} from 'store/tasks/simpleProject.async'
import { useParams } from 'react-router-dom'

const tasks = (state: RootState) => state.tasks

export const SimpleProject = () => {
  const { projectId } = useParams()

  const dispatch = useDispatch()

  const { taskList, offsetPage, selectedStatusFilter, selectedCode } =
    useSelector(tasks)

  const { selectedListItem } = useSelector((state: RootState) => state.layout)

  // Fetch tasks list
  useEffect(() => {
    // build gql filters
    const filters = buildFilters({
      status: selectedStatusFilter,
      code: selectedCode,
    })
    dispatch(fetchSimpleProjectTasks({ filters, offset: offsetPage }))
  }, [offsetPage, selectedStatusFilter, selectedCode])

  useEffect(() => {
    dispatch(getSimpleProjectSchema(projectId, selectedListItem?.orderPk))
  }, [selectedListItem])

  return (
    <Layout>
      <SimpleProjectMap />
      <SideBarDrawer>
        <Basemaps />
        <HeaderSideList />
        <TaskList tasks={taskList} />
        <SideListPagination />
      </SideBarDrawer>
    </Layout>
  )
}
