import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import {
  updateDrawingTools,
  toggleDrawnFeature,
  updateSelectedRecord,
} from 'store/layers'
import { toggleFormDrawer } from 'store/layout'
import { Map } from 'ol'
import toast from 'react-hot-toast'

import { checkTaskGeometry, getSelectedProject } from 'utils/tasks.utils'
import { startTracking } from 'utils/trackCurrentPosition'
import { useTranslation } from '@flint/locales'
import { useDrawingTools } from 'hooks/useDrawingTools'
import { ConfirmModal } from 'components/ConfirmModal'
import { Draw } from 'ol/interaction'
import { DrawEvent } from 'ol/interaction/Draw'
import WKT from 'ol/format/WKT'
import { useStyles } from './DrawingToolsBar.style'
import DrawingToolsBarItems from './DrawingToolsBarItems'

export function DrawingToolsBar({ map }: { map: Map }) {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()

  const currProject = getSelectedProject()

  const { drawingTools, drawnFeature, selectedRecord } = useSelector(
    (state: RootState) => state.layers
  )
  const { selectedListItem } = useSelector((state: RootState) => state.layout)
  const { geomType } = drawingTools
  // menu ui action
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  // confirmation  modal
  const [openModal, setOpenModal] = useState(false)

  // onsave function based on the selected project
  const onSave =
    currProject === 'vd'
      ? (wkt: string) => {
          dispatch(updateSelectedRecord({ ...selectedRecord, wkt }))
          dispatch(toggleFormDrawer(true))
        }
      : async (wkt: string) => {
          let valid = true
          // check if the the task geometry is valid
          if (currProject !== 'simple-project') {
            const result = await checkTaskGeometry(wkt, selectedListItem?.id)
            valid = result.valid
          }
          if (valid) {
            dispatch(updateSelectedRecord({ ...selectedRecord, wkt }))
            dispatch(toggleFormDrawer(true))
          } else {
            toast.error(t('please-draw-within-allowed-region'))
          }
        }

  const onDrawEnd = async (event: DrawEvent, draw: Draw) => {
    const { feature } = event

    dispatch(toggleDrawnFeature(true))
    draw.setActive(false)

    // if the offers project is selected
    if (currProject !== 'vd') {
      const geom = new WKT().writeFeature(feature, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857',
      })
      onSave(geom)
    }
  }

  const {
    handleSaveFunction,
    handleDeleteFunction,
    handleGeomTypeChange,
    handleUndoFunction,
  } = useDrawingTools(map, onSave, onDrawEnd)

  const handleToggleDrawingToolsBar = (value: boolean) => {
    dispatch(updateDrawingTools({ ...drawingTools, openFlag: value }))
    dispatch(toggleDrawnFeature(value))
  }

  const onActionClick = (e) => {
    const value = e?.currentTarget?.getAttribute('value') ?? e
    switch (value) {
      case 'close':
        // show a confirmation modal if there's a drawn feature, else close the toolsbar
        if (drawnFeature) setOpenModal(true)
        else {
          handleToggleDrawingToolsBar(false)
          setOpenModal(false)
        }
        break
      case 'save':
        handleSaveFunction()
        dispatch(toggleDrawnFeature(false))
        break
      case 'delete':
        handleDeleteFunction(geomType)
        dispatch(toggleDrawnFeature(false))
        break
      case 'location':
        startTracking(map)
        break
      case 'Point':
        dispatch(updateDrawingTools({ ...drawingTools, geomType: value }))
        handleGeomTypeChange(value)
        break
      case 'Polygon':
        dispatch(updateDrawingTools({ ...drawingTools, geomType: value }))
        handleGeomTypeChange(value)
        break
      case 'LineString':
        dispatch(updateDrawingTools({ ...drawingTools, geomType: value }))
        handleGeomTypeChange(value)
        break
      case 'undo':
        handleUndoFunction()
        break
      default:
        break
    }
    handleClose()
  }

  return (
    <div className={classes.root}>
      <DrawingToolsBarItems
        handleClose={handleClose}
        handleClick={handleClick}
        anchorEl={anchorEl}
        onActionClick={onActionClick}
        geomType={geomType}
      />
      <ConfirmModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        onConfirm={() => handleToggleDrawingToolsBar(false)}
      />
    </div>
  )
}
