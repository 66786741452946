import { Dispatch } from 'react'
import toast from 'react-hot-toast'
import { SimpleProjectService } from 'services/simpleProject.service'
import {
  toggleDataLoading,
  toggleFormDrawer,
  updateSelectedListItem,
} from 'store/layout'
import { buildFilters, formatListItem, logError, raiseSentryError } from 'utils'
import { gqlClient } from 'lib/graphql'
import {
  GET_SIMPLE_RECORD,
  SIMPLE_PROJECT_FORM_SCHEMA,
  UPLOAD_GPX_FILE,
} from 'global'
import { RootState } from 'store/reducer'
import {
  updateSelectedRecord,
  updateSimpleProjectJsonSchema,
} from 'store/layers'
import {
  setSelectedRecordSchema,
  setTasksCount,
  updateTaskList,
} from './tasks.actions'

/**
 * Service instance for interacting with Simple Project tasks.
 */
const taskClient = new SimpleProjectService()

/**
 * Fetches Simple Project tasks based on specified filters and offset.
 * @param filters - Filters to apply to the task list.
 * @param offset - Offset for pagination.
 * @returns A function that dispatches actions to update the task list.
 */
export const fetchSimpleProjectTasks = ({ filters, offset }) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(toggleDataLoading(true))
    try {
      const { data } = await taskClient.getTaskList(offset, filters)
      const tasksCount = data?.rasedTasks?.count
      const firstTask = data?.rasedTasks?.data[0] || null
      dispatch(setTasksCount(tasksCount))
      dispatch(updateTaskList(data?.rasedTasks?.data || []))
      dispatch(
        updateSelectedListItem(formatListItem(firstTask, 'task', 'simple'))
      )
    } catch (error) {
      logError('GraphQlError', error)
    } finally {
      dispatch(toggleDataLoading(false))
    }
  }
}

/**
 * Retrieves the simple project json schema.
 * @returns A function that dispatches an action to update the simple project schema.
 */
export const getSimpleProjectSchema = (projectId: string, orderPk: string) => {
  return async (dispatch: Dispatch<any>) => {
    const { data } = await gqlClient.query({
      query: SIMPLE_PROJECT_FORM_SCHEMA,
      variables: { projectId, pk: orderPk },
    })

    const schema = data?.orders?.data[0]?.jsonSchema?.rased || {}

    dispatch(
      updateSimpleProjectJsonSchema({
        jsonSchema: schema?.form,
        webUiJsonSchema: schema?.webUISchema,
      })
    )
  }
}

export const takeSimpleTaskAction = ({
  id,
  actionType,
}: {
  id: string
  actionType: 'close' | 'reject'
}) => {
  return async (dispatch: Dispatch<any>, getState) => {
    try {
      await taskClient.takeAction({
        actionType,
        taskId: id,
        onSuccess: async () => {
          toast.success(
            actionType === 'close'
              ? 'تم إنهاء المهمة بنجاح'
              : 'تم رفض المهمة بنجاح',
            {
              duration: 1500,
            }
          )
          const { offsetPage, selectedStatusFilter, selectedCode } =
            getState()?.tasks ?? {}
          const filters = buildFilters({
            status: selectedStatusFilter,
            code: selectedCode,
          })
          dispatch(
            fetchSimpleProjectTasks({ filters, offset: offsetPage ?? 0 })
          )
        },
        onFail: () => {
          toast.error(
            actionType === 'close' ? 'فشل إنهاء المهمة ' : 'فشل رفض المهمة ',
            {
              duration: 1500,
            }
          )
        },
      })
    } catch (error) {
      logError('GraphQlError', error)
      raiseSentryError(
        error,
        {},
        {
          scope: 'Simple Project Service',
          action: `taking an action of type ${actionType} on task id ${id}`,
        }
      )
    }
  }
}

/**
 * Sets the selected feature.
 * @returns A function that dispatches actions to update the selected feature and record.
 */
export const setSimpleSelectedFeature = () => {
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    dispatch(toggleDataLoading(true))
    try {
      const taskId = getState()?.layout?.selectedListItem?.id
      const selectedFeatureProps =
        getState()?.tasks?.selectedFeature?.properties

      const featData = await getSimpleRecord({
        taskId,
        pk: selectedFeatureProps?.id,
      })

      const selectedFeatureSchema = getState()?.layers.simpleProjectJsonSchema

      const newRecord = {
        id: selectedFeatureProps?.id,
        data: {
          ...featData?.data[0]?.data,
          ...featData?.data[0]?.data?.rased,
        },
        layerId: selectedFeatureProps?.layer_id,
      }

      dispatch(updateSelectedRecord(newRecord))
      dispatch(
        setSelectedRecordSchema({
          jsonSchema: selectedFeatureSchema?.jsonSchema,
          webUiJsonSchema: selectedFeatureSchema?.webUiJsonSchema,
        })
      )

      dispatch(toggleDataLoading(false))
      dispatch(toggleFormDrawer(true))
    } catch (error) {
      logError('GraphQlError', error)
    } finally {
      dispatch(toggleDataLoading(false))
    }
  }
}

/**
 * Retrieves Simple Project record.
 * @param taskId - The ID of the task.
 * @param pk - The primary key of the record.
 * @returns The Simple Project record.
 */
export const getSimpleRecord = async ({ taskId, pk }) => {
  // pk here refer to record id
  const { data } = await gqlClient.query({
    query: GET_SIMPLE_RECORD,
    variables: { taskId, pk },
  })
  return data?.records
}

export const uploadGpxFile = async ({ taskId, gpxUrls }) => {
  try {
    const { data } = await gqlClient.mutate({
      mutation: UPLOAD_GPX_FILE,
      variables: { taskInput: { taskId, gpxUrls } },
    })
    return data?.rasedUploadTaskGpx?.task
  } catch (err) {
    raiseSentryError(err, {
      uploading_gpx_urls: {
        action: 'uploading-gpx-urls',
        gpxUrls,
        taskId,
      },
    })
  }
}

export const refetchAndUpdateSelectedTask = () => {
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    try {
      const taskId = getState()?.layout?.selectedListItem?.id
      const res = await taskClient.getTaskDetails(taskId)
      const taskItem = res?.data?.rasedTasks?.data?.at(0)
      dispatch(updateSelectedListItem(formatListItem(taskItem, 'task')))
    } catch (err) {
      logError('UnknownError', err)
    }
  }
}
