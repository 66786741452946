import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Divider } from '@material-ui/core'
import validator from '@rjsf/validator-ajv8'
import toast from 'react-hot-toast'
import { useTranslation } from '@flint/locales'
import { ReactJsonSchemaForm } from '@flint/rjsf'
import { RootState } from 'store'
import { ConfirmModal } from 'components'
import { AddPhotoToGallery } from 'Rjsf'
import { resetWMSLayers } from 'store/layers'
import { ORGANIZATION_ID } from 'shared'
import { toggleDataLoading, toggleFormDrawer } from 'store/layout'
import { SimpleProjectService } from 'services/simpleProject.service'
import { compressImg, logError } from 'utils/general.utils'
import { useStyle } from './RecordForm/RecordForm.style'
import schema from '../jsonschemas/infrastructure/infrastructure_1/infrastructure_one.json'
import uiSchema from '../jsonschemas/infrastructure/infrastructure_1/web_ui_schema.json'

export function TestSchema() {
  const { t } = useTranslation()
  const classes = useStyle()
  const dispatch = useDispatch()

  const { selectedRecord, resetWMSFlag } = useSelector(
    (rootState: RootState) => rootState.layers
  )

  const { isDataLoading, selectedListItem } = useSelector(
    (rootState: RootState) => rootState.layout
  )

  const TaskServiceInstance = new SimpleProjectService()
  const [formData, setFormData] = useState(selectedRecord?.data)
  // confirmation  modal for delete offer
  const [openModal, setOpenModal] = useState(false)

  const updateExisting = () => {
    const data = {
      recordId: selectedRecord?.id,
      taskId: selectedListItem?.id,
      formData: JSON.stringify(formData || {}),
    }
    TaskServiceInstance.updateRecord({
      ...data,
      onSuccess: () => {
        toast.success(t('saved successfully'), {
          duration: 1500,
        })
      },
      onFail: () => {
        toast.error(t('offer not edited'), {
          duration: 1500,
        })
      },
    })
  }

  console.log({ formData })
  const onSubmit = () => {
    dispatch(toggleDataLoading(true))
    updateExisting()
    dispatch(resetWMSLayers(!resetWMSFlag))
    dispatch(toggleDataLoading(false))
    dispatch(toggleFormDrawer(false))
  }

  const onCancel = () => {
    if (isDataLoading) return
    dispatch(toggleFormDrawer(false))
  }
  const onDelete = () => {
    const recordInput = {
      id: selectedRecord.id,
      taskId: selectedListItem.id,
    }
    TaskServiceInstance.deleteFieldRecord({
      recordInput,
      onSuccess: () => {
        toast.success(t('offer deleted successfully'), {
          duration: 1500,
        })
      },
      onFail: () => {
        toast.error(t('error deleting offer'), {
          duration: 1500,
        })
      },
    })
    dispatch(resetWMSLayers(!resetWMSFlag))
    dispatch(toggleDataLoading(false))
    dispatch(toggleFormDrawer(false))
  }

  const onError = (error: any) => {
    toast.error(t('form_error_msg'), {
      duration: 1500,
    })
    logError('UnknownError', error)
  }

  const onFileChange = async (data: Array<{ file: File }> | { file: File }) => {
    let response: any
    dispatch(toggleDataLoading(true))
    try {
      if (Array.isArray(data)) {
        const promises = data.map(async ({ file }) => {
          const compressedFile = await compressImg(file)
          return TaskServiceInstance.uploadViaSignedUrl(
            compressedFile,
            selectedListItem?.id,
            ORGANIZATION_ID,
            TaskServiceInstance.uploadFile
          )
        })
        response = await Promise.all(promises)
      } else {
        const compressedFile = await compressImg(data?.file)
        response = await TaskServiceInstance.uploadViaSignedUrl(
          compressedFile,
          selectedListItem?.id,
          ORGANIZATION_ID,
          TaskServiceInstance.uploadFile
        )
      }
    } catch (error) {
      logError('UnknownError', error, [
        'simple project',
        'onFileChange handler',
      ])
      dispatch(toggleDataLoading(false))
      return
    }
    dispatch(toggleDataLoading(false))
    return response
  }

  return (
    <div className={classes.entrySection}>
      <div className={classes.entrySectionFormSchemaContainer}>
        <ReactJsonSchemaForm
          transformErrors={(errors) => {
            const modfiedErrors = errors?.map((err) => {
              if (err.name === 'required' || err.name === 'minItems') {
                return { ...err, message: 'حقل مطلوب' }
              }
              if (err.name === 'enum') {
                return { ...err, message: 'يرجى الإختيار من القيم الموجودة' }
              }
              return err
            })

            return modfiedErrors
          }}
          validator={validator}
          experimental_defaultFormStateBehavior={{
            allOf: 'populateDefaults',
          }}
          onSubmit={onSubmit}
          onError={onError}
          schema={schema as any}
          uiSchema={uiSchema as any}
          formData={formData}
          setFormData={setFormData}
          liveValidate={false}
          showErrorList={false}
          disabled={isDataLoading}
          formContext={{ onFileChange }}
          omitExtraData
          liveOmit
          noHtml5Validate
          widgets={{ AddPhotoToGallery }}
        >
          <Divider />
          {/* <input ref={submitButtonRef as any} type="submit" value="" hidden /> */}
          <div className={classes.actionsContainer}>
            {/* <input type="submit" hidden /> */}
            <Button
              className={classes.actionButton}
              variant="contained"
              color="primary"
              size="small"
              fullWidth
              disableElevation
              disabled={isDataLoading}
              // onClick={onSave}
              type="submit"
            >
              {t('save')}
            </Button>
            <Button
              onClick={onCancel}
              className={classes.actionButton}
              variant="contained"
              color="default"
              size="small"
              type="reset"
              fullWidth
              disableElevation
              disabled={isDataLoading}
            >
              {t('cancel')}
            </Button>
          </div>
        </ReactJsonSchemaForm>
      </div>
      <ConfirmModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        onConfirm={onDelete}
        content="attention alert body"
      />
    </div>
  )
}
