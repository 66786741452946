import { gql } from '@apollo/client'

// export const UPDATE_RECORD_TASK = gql`
//   mutation UpdateRecordTask($recordInput: UpdateRecordInputType) {
//     rasedUpdateRecord(recordInput: $recordInput) {
//       record {
//         mapData
//         data
//         id
//         geometry
//       }
//     }
//   }
// `

export const CREATE_SIMPLE_SIGNED_URL_TASK = gql`
  mutation CreateSignedUrlTask($orderId: Int!, $fileExtension: String!) {
    signedUrl(orderId: $orderId, fileExtension: $fileExtension) {
      blobUrl
      signedUrl
    }
  }
`

export const CREATE_SIMPLE_RECORD_TASK = gql`
  mutation CreateRecordTask($recordInput: UpdateRecordInputType!) {
    rasedUpdateRecord(recordInput: $recordInput) {
      record {
        id
        geometry
        data
        mapData
      }
    }
  }
`

export const CLOSE_SIMPLE_TASK = gql`
  mutation closeRasedTask($taskInput: TaskStatusInputType!) {
    rasedCloseTask(taskInput: $taskInput) {
      task {
        id
        status
      }
    }
  }
`

export const REJECT_SIMPLE_TASK = gql`
  mutation rejectRasedTask($taskInput: TaskStatusInputType!) {
    rasedRejectTask(taskInput: $taskInput) {
      task {
        id
        status
      }
    }
  }
`
export const UPDATE_SIMPLE_RECORD_TASK = gql`
  mutation UpdateRecordTask($recordInput: UpdateRecordInputType) {
    rasedUpdateRecord(recordInput: $recordInput) {
      record {
        mapData
        data
        id
        geometry
      }
    }
  }
`
export const DELETE_SIMPLE_FIELD_RECORD = gql`
  mutation DeleteFieldRecord($recordInput: DeleteRecordInputType!) {
    deleteRasedRecord(recordInput: $recordInput) {
      status
    }
  }
`
export const UPLOAD_GPX_FILE = gql`
  mutation UploadGpxFile($taskInput: GPXInputType!) {
    rasedUploadTaskGpx(taskInput: $taskInput) {
      task {
        id
        gpxUrls
      }
    }
  }
`
